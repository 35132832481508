<template>
  <b-overlay rounded="sm" :show="loading">
    <AuthWrapper>
      <template #card>
        <div class="text-center">
          <div>
            <Check />
          </div>
          <div class="mt-3 mb-1">
            <h1>Registration Successful</h1>
          </div>
          <div>
            <p>
              Welcome to the Exxtra Family, requesting a loan could not get any easier with us!
              It is however, important to note that NSS loan requests can be made only after your posting and E-zwich account is validated.
              Stay up to date with @exxtra and @exxtra.app on Facebook and Instagram for updates. Do More with Exxtra!
            </p>
          </div>

          <div class="pt-1">
            <p class="text-center m-0 p-0 cursor-pointer" @click="logout">
              <feather-icon icon="LogOutIcon" />
              Logout
            </p>
          </div>

        </div>
      </template>
    </AuthWrapper>
  </b-overlay>
</template>
  
<script>
  import { BOverlay } from "bootstrap-vue";
  import Check from "@/assets/svg/Check.svg";
  import AuthWrapper from "@core/components/auth-wrapper/AuthWrapper.vue";

  export default {
    components: {
      AuthWrapper,
      Check,
      BOverlay
    },
    data() {
      return {
        loading: false
      }
    },
    computed: {},
    methods: {}
  }
</script>
